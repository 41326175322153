import * as Yup from "yup";

import "yup-phone";

const email = Yup.string().email("Invalid email").required("Required field");

const firstName = Yup.string()
  .required("Required field")
  .matches(/^\w/, "Only letters and hyphens");

const lastName = Yup.string().required("Required field");

export const password = Yup.string()
  .min(8, "Seems a bit short...")
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
    "Must Contain 8 Characters\nOne Uppercase\nOne Lowercase\nOne Number"
  )
  .required("Required field");

const confirmPassword = Yup.string()
  .required()
  .label("Confirm password")
  .test("passwords-match", "Passwords must match", function (value) {
    return this.parent.password === value;
  });

const confirmationCode = Yup.string().required("Required");

const address = Yup.string().required("Required field");

const phone = Yup.string()
  .min(10, "Follow 0123456789 format (numbers only)")
  .required("Required field")
  .typeError("Must be a number")
  .matches(/\d{10}/gm, "Follow 0123456789 format (numbers only)");

const maskedPhone = Yup.string()
  .required("Required field")
  .matches(/\+1 \(\d\d\d\) \d\d\d-\d\d\d\d/gm, "Required field");

const maskedConfirmationCode = Yup.string()
  .required("Required field")
  .matches(/\d{6}/gm, "Required field");

const companySchema = {
  company: Yup.string().required("Required field"),
  email: email,
  taxId: Yup.string()
    .matches(/^\d{2}-?\d{7}$/, "An EIN contains 9 digits")
    .required("Required field"),
  address: address,
};

export const updateCompanySchema = {
  companyId: Yup.string().required("Required field"),
  companyEmail: email,
  federalTaxId: Yup.string()
    .matches(/^\d{2}-?\d{7}$/, "An EIN contains 9 digits")
    .required("Required field"),
  companyName: Yup.string().required("Required field"),
  address: address,
};

const providerSchema = {
  username: email,
  password: Yup.string().required("Required field"),
  newPassword: password,
  phoneNumber: phone,
  firstName: firstName,
  lastName: lastName,
  address: address,
  licenseNumber: Yup.string()
    .min(1, "Must be more than 1 character")
    .max(49, "Must be less than 50 characters")
    .required("Required field"),
  trainingCertificate: Yup.string()
    .min(1, "Must be more than 1 character")
    .max(49, "Must be less than 50 characters")
    .required("Required field"),
};

const forgotPasswordSchema = {
  email: email,
  code: confirmationCode,
  password: password,
  confirmPassword: confirmPassword,
};

const profileSchema = {
  firstName: firstName,
  lastName: lastName,
  phone: maskedPhone,
  adminEmail: email,
  password: password,
  confirmPassword: confirmPassword,
};

const baaSignatureSchema = {
  isBaaDownloaded: Yup.bool().test("is-baa-downloaded", "Document must be downloaded", function (value) {
    return !!value;
  }),
  baaSignature: Yup.string().required("Required field"),
}

const paymentSchema = {
  paymentName: Yup.string()
    .matches(
      /^[A-Z]|[a-z]+\s[A-Z]|[a-z]+$/,
      "Enter only the first and last names on the card, separated by a space"
    )
    .required("Required field"),
  cardNumber: Yup.string()
    .matches(/^(\D*\d){13,16}\D*$/, "Number should be between 13 and 16 digits")
    .required("Required field"),
  expiryDate: Yup.string()
    .matches(
      /^(0[1-9]|1[0-2])\s\/?\s([0-9]{4}|[0-9]{2})$/gm,
      "Expiry date is invalid"
    )
    .required("Required field"),
  securityCode: Yup.string()
    .matches(/^[0-9]{3,4}$/, "Security code is invalid")
    .required("Required field"),
  zipCode: Yup.string()
    .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, "Zip code is invalid.")
    .required("Required field"),
};

export const PasswordRules = {
  passwordRules: [
    "Have 8 or more characters.",
    "Contain at least one number.",
    "Contain at least one small letter.",
    "Contain at least one capital letter.",
  ],
};

export const registerAdminRequestSchema = {
  firstName: firstName,
  lastName: lastName,
  companyName: companySchema.company,
  username: email,
  companyEmail: email,
  phoneNumber: Yup.string()
    .matches(/^\+\d{11}$/g)
    .required(),
  address: address,
  federalTaxId: companySchema.taxId,
  newPassword: profileSchema.password,
  expiryMonth: Yup.string()
    .matches(/^(0[1-9]|1[0-2])$/g)
    .required(),
  expiryYear: Yup.string()
    .matches(/^\d{4}$/g)
    .required(),
  securityCode: paymentSchema.securityCode,
  zipCode: paymentSchema.zipCode,
};

export const validationSchemas = {
  companySchema,
  profileSchema,
  baaSignatureSchema,
  paymentSchema,
  forgotPasswordSchema,
  providerSchema,
};

export const SMSPhoneSchema = {
  phoneNumber: maskedPhone,
};

export const SMSCodeSchema = {
  code: maskedConfirmationCode,
};

export const getYupValidationSchema = (obj: object) =>
  Yup.object().shape({ ...obj });

export const loginSchema = {
  email: email,
  password: Yup.string().required("Required field"),
};

export const sendCodeSchema = {
  email: email,
};

export const resetPasswordSchema = {
  code: confirmationCode,
  password: password,
  confirmPassword: confirmPassword,
};

export const updateUserInfoSchema = {
  address: address,
  trainingCertificate: Yup.string()
    .min(1, "Must be more than 1 character")
    .max(49, "Must be less than 50 characters")
    .required("Required field"),
  licenseNumber: Yup.string()
    .min(1, "Must be more than 1 character")
    .max(49, "Must be less than 50 characters")
    .required("Required field"),
  phoneNumber: phone,
  firstName: firstName,
  lastName: lastName,
};

const zipCodeValidationSchema = Yup.string()
  .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, "Zip code is invalid.")
  .required("Required");

export const PosOnboardingValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  state: Yup.string().required("Required"),
  zip: zipCodeValidationSchema,
  ssn: Yup.string()
    .matches(/^\d{9}$/, "Must be a 9 digit number")
    .required("Required"),
  email: Yup.string().email("Invalid email format").required("Required"),
  phoneNumber: Yup.string()
    .phone("US", true, "Must be a valid US phone number")
    .required("Required")
    .matches(/\d\d\d-\d\d\d-\d\d\d\d/gm, "Must be in [000]-[000]-[0000] format"),
  dbaName: Yup.string().required("Required"),
  dbaAddress: Yup.string().required("Required"),
  dbaCity: Yup.string().required("Required"),
  dbaState: Yup.string().required("Required"),
  dbaZip: zipCodeValidationSchema,
  isLbAddressSameAsDba: Yup.boolean(),
  lbName: Yup.string().when("isLbAddressSameAsDba", {
    is: false,
    then: Yup.string().required("Required"),
  }),
  lbAddress: Yup.string().when("isLbAddressSameAsDba", {
    is: false,
    then: Yup.string().required("Required"),
  }),
  lbCity: Yup.string().when("isLbAddressSameAsDba", {
    is: false,
    then: Yup.string().required("Required"),
  }),
  lbState: Yup.string().when("isLbAddressSameAsDba", {
    is: false,
    then: Yup.string().required("Required"),
  }),
  lbZip: Yup.string().when("isLbAddressSameAsDba", {
    is: false,
    then: zipCodeValidationSchema,
  }),
  taxId: Yup.string()
    .matches(/^\d{2}-?\d{7}$/, "Must be a 9 digit number")
    .required("Required"),
  nameOnAccount: Yup.string().required("Required"),
  routingNumber: Yup.string()
    .matches(/^\d{9}$/, "Must be a 9 digit number")
    .required("Required"),
  accountNumber: Yup.string()
    .matches(/^\d{5,17}$/, "Must be a 5 to 17 digit number")
    .required("Required"),
});
